export const APP_VERSION = process.env.REACT_APP_VERSION

export const LINKER_DOMAIN = 'https://kickbacks.page.link'

export const EMAIL_PUBLIC = 'info@sessia.com'

export const APP_ID_USER = 'web.kicksback.app'
// export const APP_ID_BUSINESS = 'business.kicksback.app' // deprecated
export const AUTH_PACKAGE = 'web-2020'

export const REQUEST_HEADER_NAME_APP_ID = 'X-Sessia-Package'
export const REQUEST_HEADER_NAME_APP_TOKEN = 'X-Sessia-Token'

export const API_REQUEST_TIME_INTERVAL = 2000 // 2s

export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token'
export const GRANT_TYPE_SMS = 'http://thesession.ru'
export const GRANT_TYPE_SMS_BUSINESS = 'http://thesession.ru/grant/business'
export const GRANT_TYPE_CALL = 'https://api.sessia.com/grant/sms_ru'

export const SMS_AUTH_CLIENT_ID = '16_481uri5cqy0wowc8w0kwscgk88gwkws844oocwwk8os80oc8gg'
export const SMS_AUTH_CLIENT_ID_BUSINESS = '43_3zpymtao47y8c4wwwsksg4ww0gwk4kk0okwo8wo4o4k8koggos'
export const SMS_CLIENT_SECRET = '1hwxzfws8llwwcssgw4oooco0g0gkoso4c08o80wskw8gckww8'
export const SMS_CLIENT_SECRET_BUSINESS = '56mf5sizwpgc0cgscso0go8k4k8w4kwc0goksgo8oc08ockggc'

export const CALL_AUTH_CLIENT_ID = '42_1zopzovrhlz4swwwgksk4g88sw40cscksk0kkkcgckccs0w00'
export const CALL_CLIENT_SECRET = '246xt7lp99ogw8ss8o4swkoos8go44ogwws4kg0s8ggwckkkc8'

export const PHONE_ID = '1c6d3e0212c427b3A'

export const USER_SMS_CODE_MASK_CHARS_COUNT = 4
export const ADMIN_SMS_CODE_MASK_CHARS_COUNT = 7

export const POST_IMAGE_PLACEHOLDER_RESOURCE = 'preview_placeholder_picture'
export const IMAGE_PLACEHOLDER_RESOURCE = '3-4_image_placeholder_png'

export const COMPANY_ACCOUNT_ID_5 = 5
export const POST_IMAGE_MAX_SIDE_SIZE = 1600
export const POST_COMMUNITY_CHECKS_ID = 43
export const POST_COUNT_ACTIVE_COMMUNITY = 1
export const POST_TYPE_POST = 1
export const POST_TYPE_EVENT = 3

export const ANDROID_APP_STORE = 'https://play.google.com/store/apps/details?id=com.sessia.kickbacks'
export const ANDROID_APP_BUSINESS_STORE = 'https://play.google.com/store/apps/details?id=com.sessia.business'
export const IOS_APP_STORE = 'https://apps.apple.com/ru/app/kicksback/id1546115424'
export const IOS_APP_BUSINESS_STORE = 'https://apps.apple.com/app/id1414883041'

/**
 * Аттачи, в посте отображаются иконками.
 * Может быть следующих типов:
 * - картинка (type начинаемся с image),
 * - видео (type начинается с video),
 * - документ (теоретически может быть любой файл, кроме картинкаи и видео)
 */
export const POST_FILE_GROUP_ID_0 = 0

/**
 * Отображаются в ленте и внутри поста вначале
 */
export const POST_FILE_GROUP_ID_1 = 1

/**
 * Отображаются только в ленте
 */
export const POST_FILE_GROUP_ID_2 = 2

/**
 * Отображаются только внутри поста вначале.
 * Галерея над заголовком внутри поста.
 * В посте суммируем с типом 1. Сортировку делается по ID аттача.
 */
export const POST_FILE_GROUP_ID_3 = 3

/**
 * Для размещения в теле поста, либо просто хранения аттачей по группам.
 * Если пихаем аттачи в тела поста не галереей, а отдельными файлами,
 * то хорошей практикой будет собрать эти аттачи в галерю с id=4.
 * Галерея с любым ID (в т.ч. >3) начинает "жить", только когда содержится в тексте поста.
 * @example
 * <gallery id="10"></gallery>
 */
export const POST_FILE_GROUP_ID_4 = 4

export const CAPTCHA_SCRIPT_URL = 'https://static.geetest.com/static/js/gt.0.4.9.js'

export const LINE_BREAK_CHAR = '\\n'
export const LINE_BREAK_CHAR1 = '\n'
export const LINE_BREAK_CHAR2 = '\r\n'

export const REGEXP_RU_YEAR_SYMBOL = /\s*г\./
export const REGEXP_FILE_URL = /\.([0-9a-z]+)(?:[?#]|$)/
export const REGEXP_TAGS = /(?:<).*?(?:>)/ // /<\/?[^>]+(>|$)/
export const REGEXP_TAG_BR = /<br\s*\/?>/
export const REGEXP_STICKER = /\{\[\w+,\d+\]\}/ // {[smile,32]}
export const REGEXP_STICKER_CODE = /(\w+,\d+)/ // smile,32 // (?<=\{\[)(\w+,\d+)(?=\]\})
export const REGEXP_DATE_DEFAULT = /^\d{4}([-])\d{2}\1\d{2}$/
export const REGEXP_CONTENT_TAGS = /(<image id=".*?">.*?<\/image>)|(<gallery id=".*?">.*?<\/gallery>)|(<file id=".*?">.*?<\/file>)/
export const REGEXP_DATA_IMAGE_BASE64 = /^data:image\/\w+;base64,/
export const REGEXP_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const MASK_PLACEHOLDER_CHAR = '_'

export const SMS_TIMER = 60000 // 1min
export const CHECK_CALL_AUTH_TIMER = 5000 // 5s
export const MINIMUM_AGE_REGISTRATION = 14

export const TRANSLATION_TIME_TO_UPDATE = 600000 // 10min
export const RESOURCE_TIME_TO_UPDATE = 3600000 // 1hour
export const COUNTS_TIME_TO_UPDATE = 120000 // 2min
export const COUNTRIES_TIME_TO_UPDATE = 86400000 // 24hour
export const LANGUAGES_TIME_TO_UPDATE = 86400000 // 24hour
export const INPUT_DEBOUNCE_TIME = 400

export const MY_FEED_ID = 27
export const SUPPORT_REPORT_ID = 30

export const REPORTS_DATE_MIN = '2017-12-01'
export const REPORT_CC_MATERIALS_ID = 'section_0_7'
export const REPORT_PV_MATERIALS_ID = 'section_0_9'

export const SLIDER_ITEM_CLASS = 'slider-slide'

export const URL_PARAM_RETURN_PATH_KEY = 'return-to'

export const CLIENT_STORAGE_APP_ID_KEY = 'app.id'
export const CLIENT_STORAGE_LANGUAGES_KEY = 'languages'
export const CLIENT_STORAGE_COUNTRIES_KEY = 'countries'
export const CLIENT_STORAGE_RESOURCES_KEY = 'resources'
export const CLIENT_STORAGE_AUTH_PHONE_KEY = 'auth.phone'
export const CLIENT_STORAGE_AUTH_DATA_KEY = 'auth.data'
export const CLIENT_STORAGE_REG_DATA_KEY = 'reg.data'
export const CLIENT_STORAGE_WALLET_CURRENCY_KEY = 'wallet.currency'
export const CLIENT_STORAGE_USER_SESSION_KEY = 'user.session'
export const CLIENT_STORAGE_USER_GUID_KEY = 'user.guid'
// export const CLIENT_STORAGE_SETTINGS_REPORTS_NEW_KEY = 'settings.reportsnew'
export const CLIENT_STORAGE_SETTINGS_FEED_TILE = 'settings.feed-tile'
export const CLIENT_STORAGE_SETTINGS_SHOP_CATALOG_EDIT = 'settings.shop-catalog-edit'
export const CLIENT_STORAGE_REPORTS_MEMBERS_KEY = 'reports.members'
export const CLIENT_STORAGE_REPORTS_CLIENTS_KEY = 'reports.clients'
export const CLIENT_STORAGE_REPORTS_PARTNERS_KEY = 'reports.partners'
export const CLIENT_STORAGE_MOBILE_DETECT_TIMEOUT_KEY = 'mobile.detect-timeout'

export const COUNTRY_ID_DEFAULT = 7 // USA

export const MARKET_WALLET_PAYOUT = 1
export const MARKET_WALLET_BONUS = 2

export const MARKET_PAYMENT_VIRTUAL = 0 // Виртуальный расчет
export const MARKET_PAYMENT_BY_BANK_TRANSFER = 1 // Безналичная форма оплаты заказа через платежный шлюз
export const MARKET_PAYMENT_BY_CASH = 2 // Оплата в офисе за нал
export const MARKET_PAYMENT_BY_TERMINAL = 3 // Оплата через терминал
export const MARKET_PAYMENT_BY_BANK_SCORE = 4 // Оплата через банковский счет
export const MARKET_PAYMENT_POST_PAYMENT = 5 // Постоплата

export const MARKET_ORDER_STATUS_NOT_FORMED = 1 // заказ в стадии формирования
export const MARKET_ORDER_STATUS_READY_PAYMENT = 2 // заказ сформирован и готов к оплате через платежную систему
export const MARKET_ORDER_STATUS_3 = 3 // обрабатывается
export const MARKET_ORDER_STATUS_WAITING_PAYMENT = 10 // заказ принят к оплате платежной системой и ожидает статуса оплаты от неё
export const MARKET_ORDER_STATUS_11 = 11 // ошибка от банка
export const MARKET_ORDER_STATUS_15 = 15 // ошибка списания денег
export const MARKET_ORDER_STATUS_16 = 16 // отменена банком
export const MARKET_ORDER_STATUS_PAID = 100 // заказ оплачен
export const MARKET_ORDER_STATUS_200 = 200 // заказ отменен

export const STORE_TYPE_CPA = 'CPA'
export const STORE_TYPE_CPA_NO_GOODS = 'CPA_NO_GOODS'
export const STORE_ID_STICKERS = 5
export const STORE_FRIENDS_SHOW_COUNT = 3

export const PRODUCT_TYPE_PROMOTION_DESCRIPTION = 420

export const URL_SEARCH_KEY_USER_LOGOUT = 'logout'

export const USER_TAG_ID_MLM_MENU = 287
export const USER_TAG_ID_CANARY_ZONE = 426

export const OTHER_FILE_UPLOAD_LOCATION = 1
export const VIDEO_FILE_UPLOAD_LOCATION = 2

export const EVENT_TYPE_ALERT_NOTIFY = 'onAlertNotify'
export const EVENT_TYPE_ALERT_DIALOG = 'onAlertDialog'

export const EVENT_TYPE_MODAL_CONTENT_OPEN = 'onModalContent'

export const EVENT_TYPE_POST_DELETE = 'onPostDelete'
export const EVENT_TYPE_POST_FILE_DELETE = 'onDeletePostFile'
export const EVENT_TYPE_POST_CONTENT_FILE_DELETE = 'onDeletePostContentFile'
export const EVENT_TYPE_POST_COMMENT_REPLY = 'onReplyPostComment'
export const EVENT_TYPE_POST_COMMENT_DELETE = 'onDeletePostComment'
export const EVENT_TYPE_POST_COMMENT_RESTORE = 'onRestorePostComment'
export const EVENT_TYPE_POST_COMMENT_USER_BLOCK = 'onPostCommentBlockUser'
export const EVENT_TYPE_POST_COMMENT_USER_UN_BLOCK = 'onPostCommentUnBlockUser'
export const EVENT_TYPE_POST_USER_UNSUBSCRIBE = 'onPostUnsubscribeUser'
export const EVENT_TYPE_POST_USER_IN_STORE_UNSUBSCRIBE = 'onPostUnsubscribeUserInStore'
export const EVENT_TYPE_POST_COMMUNITY_UNSUBSCRIBE = 'onPostUnsubscribeCommunity'
export const EVENT_TYPE_POST_COMPANY_UNSUBSCRIBE = 'onPostUnsubscribeCompany'
export const EVENT_TYPE_POST_USER_BLOCK = 'onPostBlockUser'
export const EVENT_TYPE_POST_STORE_UNSUBSCRIBE = 'onPostUnsubscribeStore'
export const EVENT_TYPE_POST_FORM_MODAL_VISIBILITY = 'onPostFormModalVisibility'

export const EVENT_TYPE_DELIVERY_ADDRESS_DELETE = 'onDeleteDeliveryAddress'

export const EVENT_TYPE_ORDER_PAYMENT_SUCCESS = 'onOrderPaymentSuccess'
export const EVENT_TYPE_ORDER_PAYMENT_FAIL = 'onOrderPaymentFail'
export const EVENT_TYPE_ORDER_PAYMENT_ERROR = 'onOrderPaymentError'

export const EVENT_TYPE_CAPTCHA_INIT = 'onCaptchaInit'

/* must match with breakpoints.css */
export const BREAKPOINTS = {
    phone: 576,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    bigDesktop: 1440,
}

export const APP_URL = {
    auth: '/auth',
    // registration: '/registration',
    registrationUser: '/registration/user',
    registrationCompany: '/registration/company',
    registrationStoreSettings: '/registration/store/settings',
    logout: '/logout',

    app: '/app',
    kick: '/kick/:id',

    lenta: '/lenta',
    post: '/post/:id',

    market: '/marketplace',
    category: '/marketplace/:id',

    shopsFavorite: '/shop', // favorite shops
    shopsSettings: '/shop/settings', // favorite shops settings
    shop: '/shop/:id', // business shop
    shopCatalog: '/shop/:id/catalog/:catalogId',
    shopHeader: '/shop/:id/header',
    shopHeaderEdit: '/shop/:id/header/:headerId',
    shopFolder: '/shop/:id/folder',
    shopFolderEdit: '/shop/:id/folder/:folderId',
    shopGoods: '/shop/:id/goods',
    shopGoodsEdit: '/shop/:id/goods/:goodsId',
    shopKicks: '/shop/:id/kicks',
    shopCashback: '/shop/:id/cashback',

    stores: '/stores', // TODO ? marketpersonal/stores
    storeCreate: '/store/create', // marketpersonal/create
    store: '/store/:id', // user store
    storeSettings: '/store/:id/settings', // marketpersonal/:id/settings
    storeProfile: '/store/:id/profile',
    storeEdit: '/store/:id/edit', // marketpersonal/:id/edit
    catalog: '/store/:id/catalog/:catalogId',
    basket: '/store/:id/basket',
    basketFromOrder: '/store/:id/basket/order/:orderId',
    orders: '/store/:id/orders',
    orderMake: '/store/:id/order/make',
    orderPayment: '/store/:id/order/:orderId/payment',

    goods: '/goods/:id',

    user: '/user/:id',
    userSettings: '/user/settings',
    profile: '/profile/:id',
    profilePosts: '/profile/:id/posts',
    profileFriends: '/profile/:id/friends',

    settings: '/settings',

    clients: '/clients',

    account: '/account/:id',
    community: '/community/:id',

    reports: '/reports',
    reportsAccounts: '/reports/accounts', // TODO ?
    reportsPayment: '/reports/payment',
    reportsProduct: '/reports/product',
    reportsIndicator: '/reports/indicator',
    report: '/report/:id',

    support: '/support',
    ticket: '/support/ticket/:id',

    friends: '/friends',
    friendsMutual: '/friends/:id',
    friendsRequest: '/friends/request',

    messenger: '/messenger',
    messengerChatNew: '/messenger/chat',
    messengerChannelNew: '/messenger/channel',
    messengerChat: '/messenger/chat/:id',
    messengerChannel: '/messenger/channel/:id',

    map: '/map',
    kicks: '/kicks',
    wallet: '/wallet',
    rating: '/rating',
    popular: '/popular',
    invite: '/invite',
    receipt: '/receipt',
    lessons: '/lessons',

    /* business */
    company: '/company',
    companyOrders: '/orders',
    companyOrder: '/orders/store/:storeId/:id',

    delivery: '/delivery',
    deliveryServices: '/delivery/services',
    deliveryTypeAdd: '/delivery/services/:id/type/add',
    deliveryTypeEdit: '/delivery/services/:id/type/edit/:typeId',

    warehouse: '/warehouse',
    supplies: '/warehouse/supplies',
    suppliesAdd: '/warehouse/supplies/add',
    leftovers: '/warehouse/leftovers',

    /* service paths */
    service: '/service',
}
