import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

import {
    APP_MODE,
    API_HOST,
    API_BASE_URL,
    API_URL,
} from 'config/api'
import {
    APP_ID_USER,
    APP_VERSION,
    API_REQUEST_TIME_INTERVAL,
    GRANT_TYPE_SMS,
    GRANT_TYPE_REFRESH_TOKEN,
    SMS_AUTH_CLIENT_ID,
    SMS_AUTH_CLIENT_ID_BUSINESS,
    CALL_AUTH_CLIENT_ID,
    SMS_CLIENT_SECRET,
    SMS_CLIENT_SECRET_BUSINESS,
    CALL_CLIENT_SECRET,
    PHONE_ID,
    REQUEST_HEADER_NAME_APP_ID,
    REQUEST_HEADER_NAME_APP_TOKEN,
    CLIENT_STORAGE_APP_ID_KEY,
} from 'config/app'
import { appLogoutUser } from 'containers/User/user-actions'
import { AuthService, UserService, StorageService } from 'services'
import CaptchaGeetestService from 'services/CaptchaGeetestService' // fix for webpack
import { AppMode } from 'enums'
import store from 'store'

export type RequestOptions = Omit<AxiosRequestConfig, 'params' | 'paramsSerializer' | 'data'>

type RequestConfigExtParams = {
    authorization?: boolean
    retryAuth?: boolean
    retryCount?: number
}

type RequestConfig<T = any> = AxiosRequestConfig<T> & RequestConfigExtParams

type RequestResponse<T> = AxiosPromise<T>

const requestClientInstance = axios.create({
    baseURL: API_BASE_URL,
    responseType: 'json',
})

requestClientInstance.interceptors.request.use((requestConfig) => {
    const { authorization, retryAuth, headers = {} } = requestConfig as RequestConfig

    if (authorization && !retryAuth) {
        const { access_token: accessToken } = AuthService.getAuthData() || {}
        const sessionCookie = UserService.getSessionData()

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }
        if (sessionCookie) {
            headers[REQUEST_HEADER_NAME_APP_TOKEN] = sessionCookie
        }
    }

    return requestConfig
}, (err) => {
    return Promise.reject(err)
})

requestClientInstance.interceptors.response.use((res) => res, (err) => {
    const { response } = err || {}
    const { config } = response || {}
    const auth = AuthService.getAuthData()

    if (!response) {
        return Promise.reject(err)
    }

    config.retryCount = 'retryCount' in config ? config.retryCount + 1 : 1

    if (response?.status === 404) {
        return Promise.reject(err)
    }

    // Access token expired or invalid
    if (response?.status === 401) {
        const {
            access_token: accessToken,
            refresh_token: refreshToken,
            grantType,
            appMode,
        } = auth || {}

        if (accessToken && refreshToken && grantType && appMode) {
            let clientId
            let clientSecret

            if (appMode === AppMode.business) {
                clientId = SMS_AUTH_CLIENT_ID_BUSINESS
                clientSecret = SMS_CLIENT_SECRET_BUSINESS
            } else {
                clientId = grantType === GRANT_TYPE_SMS ? SMS_AUTH_CLIENT_ID : CALL_AUTH_CLIENT_ID
                clientSecret = grantType === GRANT_TYPE_SMS ? SMS_CLIENT_SECRET : CALL_CLIENT_SECRET
            }

            return requestClientInstance(`${API_HOST}/${API_URL.auth}`, {
                params: {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    grant_type: GRANT_TYPE_REFRESH_TOKEN,
                    phone_id: PHONE_ID,
                    client_id: clientId,
                    client_secret: clientSecret,
                },
            })
                .then(({ data: tokens }) => {
                    const newAuth = auth ? { ...auth, ...tokens } : tokens

                    AuthService.saveAuthData(newAuth)
                    UserService.saveAuthData(newAuth)
                })
                .catch((errAuth) => {
                    if (auth) {
                        UserService.removeAuthData({ key: 'access_token', value: accessToken })
                    }
                    // @ts-ignore
                    store.dispatch(appLogoutUser())

                    return Promise.reject(errAuth)
                })
                .finally(() => {
                    config.retryAuth = true
                })
        }
    }

    // Show captcha
    if (response?.status === 429 && response?.data?.captcha) {
        return CaptchaGeetestService.initCaptcha(response.data.captcha)
            .then(() => {
                return (requestClientInstance(config.url, config))
            })
            .catch((errCaptcha) => {
                return Promise.reject(errCaptcha)
            })
    }

    // Retry request
    if (response?.status === 500 && config.retryCount < 3) {
        return new Promise((resolve) => {
            window.setTimeout(() => {
                resolve(requestClientInstance(config.url, config))
            }, config.retryCount * API_REQUEST_TIME_INTERVAL)
        })
    }

    return Promise.reject(err)
})

/**
 * @see https://github.com/axios/axios
 */
const requestClient = <T = any, S extends RequestConfig<T> = {}>(url: string, options?: S): RequestResponse<T> => {
    const defaults = {
        headers: {},
        authorization: true,
    }

    const appId = APP_MODE === AppMode.business
        ? StorageService.getItem<string>(CLIENT_STORAGE_APP_ID_KEY, true, 'session')
        : APP_ID_USER

    if (appId) {
        defaults.headers[REQUEST_HEADER_NAME_APP_ID] = `${appId}/${APP_VERSION}`
    }

    return requestClientInstance(url, { ...defaults, ...options })
}

export default requestClient
export { requestClientInstance }
