import React from 'react'
import { useTranslation } from 'react-i18next'

import { IPostContent } from '../../../../interfaces'
import { Textarea } from '../../../../components'
import style from './PostFormContentInner.module.css'

type PostFormContentInnerPropType = {
    data: IPostContent
    onChangeContentDescription: (id: number, text: string) => void
}

const PostFormContentInner: React.FC<PostFormContentInnerPropType> = ({ data, onChangeContentDescription }) => {
    const { id, text } = data

    const { t } = useTranslation()

    const handlerChangeDescription = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        const { value } = e.currentTarget
        onChangeContentDescription(Number(id), value)
    }

    return (
        <div className={style.desc}>
            <Textarea
                styleType="dynamic"
                dynamicPlaceholder={t('ui_posts_post_text_hint')}
                name="text"
                value={text}
                onBlur={handlerChangeDescription}
            />
        </div>
    )
}

export default PostFormContentInner
